import "./style.css"
import { useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { HashLink } from "react-router-hash-link"

export default function Landing() {
 
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.9,
  })
  
  useEffect(() => {
    if (inView) window.location.replace('#Landing')

  }, [inView])
  
  return (
    <div ref={ref} id="Landing">
      <h1 className="title">OS PROCESSOS DAS ABSTRAÇÕES MENTAIS 
      NA CONSTRUÇÃO DO CONHECIMENTO MUSICAL A PARTIR DE PIAGET:<br /> 
      <span className="nobold">Uma Revisão Integrativa</span></h1>
        <div className="content">
          <div className="text-content">
            <p className="text">Como se constrói o conhecimento musical? O chamado
            dom é o fator determinante para se fazer música? O que a epistemologia
            genética de Piaget diz a respeito dessa construção de conhecimento? O que
            os estudiosos da área da cognição musical, que tomam os estudos de Piaget
            como base, dizem a respeito desse assunto?<br /><br />Essas e outras questões 
            fazem parte dessa obra.<br /></p>
            <div className="button-container mobile-container">
              <HashLink className="mobile button" to="/#Buy">
                Confira!</HashLink>
            </div>
            <p className="text">Os principais fatores que promoveram a construção
            de conhecimento - maturação, abstrações mentais (empírica e lógico-matemática)
            e transmissão social - são relacionados com o desenvolvimento da inteligência
            musical. Mais de 100 pesquisas - nacionais e internacionais - se debruçaram 
            sobre este assunto para compreender melhor como a cognição musical se desenvolve
            tanto em crianças como adultos.</p>
            <img src="/images/cover.jpg" alt="Imagem do livro" className="image mobile"/>
            <div className="button-container">
              <HashLink className="button border" to="/#Buy">
                Adquira já!
              </HashLink>
            </div>
          </div>
        <img src="/images/cover.jpg" alt="Imagem do livro" className="image desktop"/>
        </div>
    </div>
  )
}
